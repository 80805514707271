.calendar-group {
  & > div {
    width: 100%;
  }
  .react-datetime-picker {
    &__wrapper {
      width: 100%;
      padding: 0px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none;
      transition: 0.2s;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      background: #f3f6f9;
      border-left: 1px solid #e2e5ec;
    }
    &__inputGroup {
      padding: 5px 10px;
    }
  }
}
