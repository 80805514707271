.client-icon {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f8;
  border-radius: 50%;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.client-title {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #48465b;
  margin-bottom: 10px;
}

.client-form-check {
  min-height: 150px;
  padding-top: 12px;
}
