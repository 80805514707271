.channel-select {
  padding: 5px 25px 20px;
  div[class$="indicatorContainer"] {
    padding: 2px 5px;
    svg {
      max-width: 15px;
      fill: #A2A5B9;
    }
  }
}
