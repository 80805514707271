.product-group-container {
  .products-list {
    font-weight: 100;
    color: #595d6e;
    & > li {
      .table-head {
        tr {
          th:not(:first-child) {
            padding-left: 10px;
          }
        }
      }
    }
    .product {
      cursor: pointer;
      &.active {
        background-color: rgb(249, 249, 249);
      }
      &:hover {
        background-color: rgba(249, 249, 249, 0.7);
      }
      border-bottom: 1px solid #ebedf2;
      tr {
        border-bottom: none;
        &:hover {
          background-color: initial;
        }
      }
      .table__body {
        margin: 0 20px;
        tr {
          td {
            padding: 12px 10px;
          }
          td:first-child {
            padding-left: 50px;
            position: relative;
            button {
              position: absolute;
              top: 50%;
              left: 30px;
              transform: translateY(-50%);
            }
          }
          td:last-child {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.product-group-container {
  .products-list {
    .inner-table {
      padding: 0 30px 30px;
      border-radius: 4px;
      cursor: default;

      .table {
        background-color: white;
        box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
        tr {
          border-bottom: 1px solid $separator !important;
        }
        &__head--inner {
          tr {
            th {
              padding: 10px;
              padding-left: 5px;
              padding-bottom: 10px;
              font-weight: 600;
            }
            th:first-child {
              padding-left: 20px;
            }
          }
        }
        &__body--inner {
          tr {
            transition: 0.2s;
            cursor: pointer;
            &:hover {
              background-color: rgba(249, 249, 249, 0.7);
            }
          }
          td {
            padding: 10px 5px;
          }
          td:first-child {
            padding-left: 20px;
          }
        }
      }
    }
  }
}
