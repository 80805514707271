@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~react-dropzone-uploader/dist/styles.css";
@import "~react-markdown-editor-lite/lib/index.css";
@import "reset.scss";
@import "colors.scss";
@import "mixins.scss";
@import "typography.scss";
@import "expano-components.scss";
@import "partials/dashboard.scss";
@import "table.scss";
@import "partials/page-loader.scss";
@import "partials/tree.scss";
@import "partials/aside-panel.scss";
@import "partials/listing-search.scss";
@import "partials/calendar.scss";
@import "partials/bottom-action.scss";
@import "partials/channel-select.scss";
@import "partials/client.scss";
@import "partials/products-table.scss";
@import "partials/product-desc.scss";
@import "partials/preview-button.scss";
@import "partials/main-table.scss";
@import "partials/top-line-actions.scss";
@import "partials/order-buttons.scss";
@import "order-view.scss";
@import "delivery-view.scss";

.rc-md-navigation {
  background: #fff;
}

.rc-md-editor .editor-container .sec-md .input {
  padding-top: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.rc-md-editor .tool-bar {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}
