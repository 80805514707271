.aside-tabs {
  background-color: #ebecf3;
  border-radius: 4px;
  overflow: hidden;
  .tab {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 7px 30px;
    min-height: 50px;
    transition: 0.2s;
    .icon {
      flex-basis: 30px;
      max-width: 30px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      svg {
        max-width: 100%;
      }
    }
    .text {
      p {
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
      }
    }
    .buttons-wrapper {
      display: flex;
      align-items: center;
      margin-right: 0;
      margin-left: auto;
      .order-buttons {
        margin-right: 5px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 2px;
      background-color: #5d78ff;
      opacity: 0;
      transition: 0.2s;
    }
    &:not(:last-child)::before {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      background-color: rgba(162, 165, 185, 0.14);
    }
    &.tab-header {
      cursor: default;
      h2 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #48465b;
      }
    }
    &.sub-header {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #595d6e;
      }
    }
    &.tab-footer {
      padding: 10px 30px;
      display: flex;
      align-items: center;
      cursor: default;
      justify-content: flex-end;
    }
    &.active {
      background-color: #fff;
      &::after {
        opacity: 1;
      }
    }
  }
  &__action {
    padding: 20px;
    text-align: center;
  }
}
