.main-table {
  &__products-list {
    transition: 0.2s;
    &.searching {
      opacity: 0.4;
    }
    .main-table:not(.table--inner) {
      border-bottom: none;
      .main-table__body,
      .main-table__head {
        & > tr {
          td,
          th {
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 20%;
            }
          }
        }
      }
      .main-table__body {
        & > tr {
          td:nth-child(1) {
            position: relative;
            padding-left: 60px;
            .button__tree {
              width: 70px;
              height: 60px;
              position: absolute;
              top: 0;
              left: 0;
              &::before{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                background-color: red;
              }
            }
          }
        }
      }
    }
    .inner-table {
      padding: 0 30px 30px;
      border-radius: 4px;
      cursor: default;

      .table {
        background-color: white;
        box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
        tr {
          border-bottom: 1px solid $separator !important;
        }
        &__head--inner {
          tr {
            th {
              padding: 10px;
              padding-bottom: 10px;
              font-weight: 600;
            }
            th:first-child {
              padding-left: 20px;
            }
          }
        }
        &__body--inner {
          td {
            padding: 10px !important;
          }
          td:first-child {
            padding-left: 20px !important;
          }
        }
      }
    }
  }
  &__product {
    cursor: pointer;
    &.active {
      background-color: rgb(249, 249, 249);
    }
    &:hover {
      background-color: rgba(249, 249, 249, 0.7);
    }
    tr {
      border-bottom: none;
      &:hover {
        background-color: initial;
      }
    }
  }
}
