.order {
  &-details {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    margin: 0;
    &__date-group {
      display: flex;
      align-items: center;
    }
    &__date {
      font-size: 13px;
      line-height: 20px;
      margin-right: 40px;
      font-weight: 300;
      color: #595d6e;
    }
  }
  &-columns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .dashboard {
      margin-bottom: 20px;
    }
    .column-left {
      flex-basis: calc(60% - 10px);
      max-width: calc(60% - 10px);
    }
    .column-right {
      flex-basis: calc(40% - 10px);
      max-width: calc(40% - 10px);
    }
  }
}
.user-link {
  position: relative;
  display: inline-block;
  padding-right: 10px;
  font-weight: 300;
  &::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 2px solid #93a2dd;
    border-bottom: 0;
    border-left: 0;
    transform: rotate(45deg);
    position: absolute;
    top: 9px;
    right: 0;
    transition: 0.2s;
  }
  span {
    font-size: 13px;
    line-height: 25px;
    color: #8da6de;
  }
  &:hover {
    text-decoration: underline;
    &::after {
      right: -4px;
    }
  }
}

.btn-download {
  display: inline-flex;
  align-items: center;
  padding-right: 10px;
  span {
    font-size: 13px;
    line-height: 25px;
    font-weight: 300;
    color: #8da6de;
  }
  svg {
    margin-left: 5px;
  }
  &:hover {
    text-decoration: underline;
  }
}

.order-client {
  &__user {
    padding: 20px 30px;
    .user {
      &-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f2f8;
        border-radius: 50%;
        margin-bottom: 10px;
      }
      &-name,
      &-id {
        font-size: 13px;
        line-height: 20px;
        color: #595d6e;
      }
      &-name {
        font-weight: 500;
        margin-bottom: 5px;
      }
      &-id {
        font-weight: 400;
      }
    }
  }
  &__address {
    padding: 20px 30px 0;
    border-top: 1px solid #ebedf2;
    .title {
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      color: #48465b;
      margin-bottom: 14px;
    }
    .group {
      padding-bottom: 18px;
      p {
        font-size: 13px;
        line-height: 25px;
        font-weight: 300;
        color: #595d6e;
      }
    }
  }
}

.row-info {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .box {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  &__label {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e;
    font-weight: 300;
    &.bold {
      font-weight: 500;
    }
  }
  .table__label {
    margin-right: 0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #ebedf2;
  }
}

.notes {
  p {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e;
    font-weight: 300;
  }
}

.logs {
  &-list {
    &__item {
      padding: 25px 30px;
      &:not(:last-child) {
        border-bottom: 1px solid #ebedf2;
      }
      p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        color: #595d6e;
        &.title {
          font-weight: 400;
          color: #646c9a;
        }
      }
    }
  }
}
.summary {
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 13px;
    span {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      color: #595d6e;
    }
    &.discount {
      span {
        color: #e42920;
      }
    }
  }
  &-details {
    padding: 8px 30px;
    border-bottom: 1px solid #ebedf2;
  }
  &-total {
    padding: 8px 30px;
    span {
      font-weight: 50;
    }
  }
}

.status-select {
  width: 200px;
  div[class$="indicatorContainer"] {
    padding: 2px 5px;
    svg {
      max-width: 15px;
      fill: #a2a5b9;
    }
  }
}
.order-lines {
  .btn-preview {
    width: 40px;
    height: 40px;
  }
}
