@import "~expano-components/styles/form.scss";
@import "~expano-components/styles/input.scss";
@import "~expano-components/styles/textarea.scss";
@import "~expano-components/styles/check.scss";
@import "~expano-components/styles/buttons.scss";
@import "~expano-components/styles/labels.scss";
@import "~expano-components/styles/tree.scss";

@import "~expano-components/styles/popup.scss";
@import "~expano-components/styles/image-uploader.scss";
@import "~expano-components/styles/breadcrumbs.scss";
@import "~expano-components/styles/actionbar.scss";
@import "~expano-components/styles/sidebar.scss";
@import "~expano-components/styles/main.scss";
@import "~expano-components/styles/login.scss";
@import "~expano-components/styles/change-app-modal.scss";

.dzu-dropzone {
  &::-webkit-scrollbar {
    display: none;
  }
  label {
    display: flex;
  }
}

.nav-item {
  &__icon {
    flex-basis: 25px;
    max-width: 25px;
  }
  path[fill],
  rect[fill] {
    transition: 0.3s;
    fill: #494b74;
  }
  path[fill="none"],
  rect[fill="none"] {
    fill: none;
  }
  &:hover,
  &.active {
    path[fill],
    rect[fill] {
      fill: #5d78ff;
    }
    path[fill="none"],
    rect[fill="none"] {
      fill: none !important;
    }
  }
}
.main-navbar__bottom {
  .tab-list__item--active {
    path[fill="none"] {
      fill: none;
    }
    rect[fill="#ffffff"] {
      fill: #5d78ff;
    }
  }
}

.wrapper {
  display: flex;
  &.jc-sb {
    justify-content: space-between;
  }
  &.jc-fs {
    justify-content: flex-start;
  }
  &.jc-fe {
    justify-content: flex-end;
  }
  &.al-c {
    align-items: center;
  }
  &.al-fe {
    align-items: flex-end;
  }
  &.al-fs {
    align-items: flex-start;
  }
}
.block {
  padding: 20px;
  flex: 1;
}

.popup.lg {
  max-width: 800px;
}
