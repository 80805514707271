.delivery-table {
  padding: 20px 0;
  .box-sm {
    flex-basis: 16%;
    max-width: 16%;
  }
  .box-md {
    flex-basis: 28%;
    max-width: 28%;
    border-left: 2px solid #ebedf2;
  }
  &__header {
    display: flex;
    align-items: center;
    .header-title {
      display: flex;
      align-items: center;
      padding: 20px 25px;
      span {
        font-size: 15px;
        line-height: 20px;
        color: #48465b;
      }
      svg {
        margin-right: 13px;
      }
    }
  }
  &__body {
    .speditor-row {
      display: flex;
      align-items: center;
      .box {
        padding: 20px 10px;
        &-sm {
          padding-left: 30px;
        }
        &-md{
          min-height: 130px;
        }
        .speditor_name {
          font-size: 13px;
          line-height: 20px;
          font-weight: 300;
          color: #595d6e;
        }
        .box-form {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          max-width: 250px;
          margin: 0 auto;
          .column {
            padding: 0 10px;
            .check-group {
              margin-top: 10px;
            }
            .inp-wrapper {
              flex-direction: row-reverse;
              label {
                min-width: 25px;
                padding-right: 0;
                padding-left: 5px;
                margin-bottom: 0;
              }
              .inp-group {
                width: 70px;
              }
            }
            .select-group {
              padding-right: 25px;
              position: relative;
              .label-form {
                position: absolute;
                right: -15px;
                width: 25px;
                top: 10px;
              }
            }
          }
        }
      }
      &:nth-child(odd) {
        background: #fbfbff;
      }
    }
  }
}
