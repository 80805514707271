.listing-search {
  display: flex;
  align-items: center;
  padding: 0 30px;
  &__info {
    flex-basis: 240px;
    max-width: 240px;
    span {
      font-size: 13px;
      line-height: 20px;
      color: rgba($color: #48465b, $alpha: 0.5);
    }
  }
  &__input {
    flex: 1;
  }
  &__select {
    flex-basis: 300px;
    max-width: 300px;
    div[class$="indicatorContainer"] {
      padding: 5px;
      svg {
        max-width: 15px;
        fill: #93a2dd;
      }
    }
  }
}
