.product-desc {
  padding: 30px;
  article {
    display: flex;
    max-width: 1015px;
    margin: 0 auto;
    padding: 30px 0;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: center;
    div:only-child {
      flex-basis: 100%;
      max-width: 100%;
    }
    div {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      h2 {
        font-size: 22px;
        line-height: 33px;
        font-weight: 600;
        letter-spacing: 0.2px;
        margin-bottom: 7px;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        color: #495962;
      }
      ul {
        display: inline;
        li {
          display: inline;
          font-size: 16px;
          line-height: 28px;
          color: #495962;
        }
      }
    }
    picture {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }
}
