@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap&subset=latin-ext");
.nav-item {
  color: $nav-item;
}

a {
  text-decoration: none;
  color: inherit;
}

.nav-category {
  color: $nav-category;
  text-transform: uppercase;
}

.navbar-text {
  font-size: 12px;
  margin: 0 5px;
  color: #fff;
}

.breadcrumb {
  color: #74788d;
  font-size: 10px;
}

.heading {
  font-size: 15px;
  color: #48465b;
  font-weight: 500;
  line-height: 20px;

  &__date {
    font-size: 13px;
    line-height: 20px;
    color: rgba($color: #595d6e, $alpha: 0.5);
    padding-left: 20px;
  }
  &__text {
    font-size: 13px;
    line-height: 20px;
    color: rgba($color: #595d6e, $alpha: 0.5);
    padding-left: 20px;
    font-weight: 400;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 10px;
      width: 1px;
      height: 20px;
      background-color: #ebedf2;
    }
  }
}

.search-text {
  color: #4950577c;
  font-weight: 400;
}

.table-head {
  th {
    color: $table-head;
    font-weight: 500;
    text-align: left;
    padding: 15px;
  }
}

.table-body {
  td {
    color: $table-head;
    font-weight: 400;
    text-align: left;
  }
}

.pagination-info {
  margin-left: 14px;
  color: #646c9a;
  font-weight: 100;
}

.label-form {
  color: $label-form;
}

.platform-name {
  color: $table-head;
  font-weight: 100;
  margin-right: 10px;
}

.country-label {
  color: #a2a5b9;
  font-weight: 100;
}

.popup-text {
  padding: 25px 30px;
  padding-bottom: 37px;
  color: #595d6e;
  font-weight: 100;
  strong {
    font-weight: 600;
  }
}

.check-group {
  .label-form {
    color: #646c9a;
  }
}

.desc-box {
  margin-bottom: 20px;
  p {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e;
  }
  .title {
    color: #646c9a;
    margin-bottom: 6px;
  }
}

.text-muted {
  color: rgba(72, 70, 91, 0.5);
}

.new-checkbox {
  .check-group{
    margin-top: 12px;
  }
}
