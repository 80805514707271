.order-buttons {
  display: flex;
  align-items: center;
  .order-button {
    margin: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 15px;
    background: #ffffff;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    &[disabled]{
      opacity: 0.4;
    }
    svg {
      max-width: 14px;
    }
    &.up {
      svg {
        transform: scaleY(-1);
      }
    }
  }
}
