@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap&subset=latin-ext);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  line-height: 20px; }

.nav-item {
  color: #a2a5b9; }

a {
  text-decoration: none;
  color: inherit; }

.nav-category {
  color: #4c4e6f;
  text-transform: uppercase; }

.navbar-text {
  font-size: 12px;
  margin: 0 5px;
  color: #fff; }

.breadcrumb {
  color: #74788d;
  font-size: 10px; }

.heading {
  font-size: 15px;
  color: #48465b;
  font-weight: 500;
  line-height: 20px; }
  .heading__date {
    font-size: 13px;
    line-height: 20px;
    color: rgba(89, 93, 110, 0.5);
    padding-left: 20px; }
  .heading__text {
    font-size: 13px;
    line-height: 20px;
    color: rgba(89, 93, 110, 0.5);
    padding-left: 20px;
    font-weight: 400;
    position: relative; }
    .heading__text::before {
      content: "";
      position: absolute;
      left: 10px;
      width: 1px;
      height: 20px;
      background-color: #ebedf2; }

.search-text {
  color: #4950577c;
  font-weight: 400; }

.table-head th {
  color: #595d6e;
  font-weight: 500;
  text-align: left;
  padding: 15px; }

.table-body td {
  color: #595d6e;
  font-weight: 400;
  text-align: left; }

.pagination-info {
  margin-left: 14px;
  color: #646c9a;
  font-weight: 100; }

.label-form {
  color: #646c9a; }

.platform-name {
  color: #595d6e;
  font-weight: 100;
  margin-right: 10px; }

.country-label {
  color: #a2a5b9;
  font-weight: 100; }

.popup-text {
  padding: 25px 30px;
  padding-bottom: 37px;
  color: #595d6e;
  font-weight: 100; }
  .popup-text strong {
    font-weight: 600; }

.check-group .label-form {
  color: #646c9a; }

.desc-box {
  margin-bottom: 20px; }
  .desc-box p {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e; }
  .desc-box .title {
    color: #646c9a;
    margin-bottom: 6px; }

.text-muted {
  color: rgba(72, 70, 91, 0.5); }

.new-checkbox .check-group {
  margin-top: 12px; }

.form-row {
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 20px);
  margin-left: -10px; }
  .form-row .form-group {
    margin: 0 10px; }
  .form-row:last-child .form-group {
    margin-bottom: 0; }
  .form-row.grid-2 .form-group {
    flex-basis: calc(50% - 20px);
    max-width: calc(50% - 20px); }
  .form-row.grid-3 .form-group {
    flex-basis: calc(33.3% - 20px);
    max-width: calc(33.3% - 20px); }
  .form-row.grid-4 .form-group {
    flex-basis: calc(25% - 20px);
    max-width: calc(25% - 20px); }

.form-group {
  flex-basis: 100%;
  max-width: 100%; }
  .form-group.label-aside .inp-wrapper {
    display: flex;
    align-items: center; }
    .form-group.label-aside .inp-wrapper .inp-group,
    .form-group.label-aside .inp-wrapper .check-group {
      width: 100%; }
    .form-group.label-aside .inp-wrapper label {
      width: initial;
      min-width: 70px;
      text-align: right;
      padding-right: 20px;
      white-space: nowrap; }
  .form-group.label-aside.error .inp-error {
    padding-left: 80px; }
  .form-group:not(:last-child) {
    margin-bottom: 10px; }
  .form-group label {
    display: inline-block;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    color: #646c9a; }
  .form-group.error label,
  .form-group.error .inp-error {
    color: #ff0000; }
  .form-group.error .inp-group input {
    border-color: #ff0000; }
  .form-group.error .inp-error {
    font-size: 11px;
    line-height: 14px;
    padding: 5px 0 0 5px; }
  .form-group.error .postfix,
  .form-group.error .prefix {
    border-color: #ff0000;
    color: #ff0000; }

.input-disabled {
  background-color: #f7f8fa;
  font-size: 13px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none; }

.inp-wrapper {
  width: 100%; }
  .inp-wrapper .inp-group {
    display: flex; }
    .inp-wrapper .inp-group input {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none;
      transition: 0.2s; }
      .inp-wrapper .inp-group input:focus, .inp-wrapper .inp-group input.visited {
        border-color: #9aabff; }
      .inp-wrapper .inp-group input[type='hidden'] {
        display: none; }
    .inp-wrapper .inp-group .form-span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      font-size: 13px;
      line-height: 15px;
      color: #495057;
      background-color: #f7f8fa;
      border: 1px solid #e2e5ec;
      padding: 10px; }
    .inp-wrapper .inp-group .postfix {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -3px; }
      .inp-wrapper .inp-group .postfix-color {
        width: 16px;
        height: 16px;
        background-color: #44b2f1;
        border: 1px solid #e2e5ec; }
    .inp-wrapper .inp-group .prefix {
      margin-right: -3px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }

.check-group {
  margin-top: 22px;
  padding-left: 25px; }
  .check-group input {
    display: none; }
    .check-group input:checked + label::after {
      opacity: 1; }
  .check-group label {
    cursor: pointer;
    color: #595d6e;
    position: relative; }
    .check-group label::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      background-color: #fff;
      border: 1px solid #d1d7e2;
      border-radius: 3px; }
    .check-group label::after {
      content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiDQoJaWQ9IkxheWVyXzEiDQoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIg0KCXhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCXg9IjBweCINCgl5PSIwcHgiDQoJdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSINCgl4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8cGF0aCBkPSJNNTA2LjIzMSw3NS41MDhjLTcuNjg5LTcuNjktMjAuMTU4LTcuNjktMjcuODQ5LDBsLTMxOS4yMSwzMTkuMjExTDMzLjYxNywyNjkuMTYzYy03LjY4OS03LjY5MS0yMC4xNTgtNy42OTEtMjcuODQ5LDANCgkJCWMtNy42OSw3LjY5LTcuNjksMjAuMTU4LDAsMjcuODQ5bDEzOS40ODEsMTM5LjQ4MWM3LjY4Nyw3LjY4NywyMC4xNiw3LjY4OSwyNy44NDksMGwzMzMuMTMzLTMzMy4xMzYNCgkJCUM1MTMuOTIxLDk1LjY2Niw1MTMuOTIxLDgzLjE5OCw1MDYuMjMxLDc1LjUwOHoiIC8+DQo8L3N2Zz4=");
      position: absolute;
      top: 1px;
      left: -19px;
      width: 10px;
      height: 10px;
      opacity: 0;
      transition: 0.2s; }
  .check-group.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .check-group .additional-info {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e; }

.check-label-aside {
  display: flex;
  align-items: baseline; }
  .check-label-aside__text {
    min-width: 70px;
    font-size: 13px;
    line-height: 20px;
    color: #646c9a; }

.check-main-label {
  color: #646c9a; }
  .check-main-label + .check-group {
    margin-top: 16px; }

.inp-wrapper {
  width: 100%; }
  .inp-wrapper .inp-group {
    display: flex; }
    .inp-wrapper .inp-group input {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none;
      transition: 0.2s; }
      .inp-wrapper .inp-group input:focus, .inp-wrapper .inp-group input.visited {
        border-color: #9aabff; }
      .inp-wrapper .inp-group input[type='hidden'] {
        display: none; }
    .inp-wrapper .inp-group .form-span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      font-size: 13px;
      line-height: 15px;
      color: #495057;
      background-color: #f7f8fa;
      border: 1px solid #e2e5ec;
      padding: 10px; }
    .inp-wrapper .inp-group .postfix {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: -3px; }
      .inp-wrapper .inp-group .postfix-color {
        width: 16px;
        height: 16px;
        background-color: #44b2f1;
        border: 1px solid #e2e5ec; }
    .inp-wrapper .inp-group .prefix {
      margin-right: -3px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }

.textarea-wrapper {
  width: 100%; }
  .textarea-wrapper .textarea-group {
    display: flex; }
    .textarea-wrapper .textarea-group textarea {
      width: 100%;
      padding: 9px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none;
      transition: 0.2s;
      font-size: 13px;
      line-height: 19px; }
      .textarea-wrapper .textarea-group textarea:focus, .textarea-wrapper .textarea-group textarea.visited {
        border-color: #9aabff; }

.check-group {
  margin-top: 22px;
  padding-left: 25px; }
  .check-group input {
    display: none; }
    .check-group input:checked + label::after {
      opacity: 1; }
  .check-group label {
    cursor: pointer;
    color: #595d6e;
    position: relative; }
    .check-group label::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      left: -22px;
      background-color: #fff;
      border: 1px solid #d1d7e2;
      border-radius: 3px; }
    .check-group label::after {
      content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiDQoJaWQ9IkxheWVyXzEiDQoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIg0KCXhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIg0KCXg9IjBweCINCgl5PSIwcHgiDQoJdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSINCgl4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8cGF0aCBkPSJNNTA2LjIzMSw3NS41MDhjLTcuNjg5LTcuNjktMjAuMTU4LTcuNjktMjcuODQ5LDBsLTMxOS4yMSwzMTkuMjExTDMzLjYxNywyNjkuMTYzYy03LjY4OS03LjY5MS0yMC4xNTgtNy42OTEtMjcuODQ5LDANCgkJCWMtNy42OSw3LjY5LTcuNjksMjAuMTU4LDAsMjcuODQ5bDEzOS40ODEsMTM5LjQ4MWM3LjY4Nyw3LjY4NywyMC4xNiw3LjY4OSwyNy44NDksMGwzMzMuMTMzLTMzMy4xMzYNCgkJCUM1MTMuOTIxLDk1LjY2Niw1MTMuOTIxLDgzLjE5OCw1MDYuMjMxLDc1LjUwOHoiIC8+DQo8L3N2Zz4=");
      position: absolute;
      top: 1px;
      left: -19px;
      width: 10px;
      height: 10px;
      opacity: 0;
      transition: 0.2s; }
  .check-group.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .check-group .additional-info {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e; }

.check-label-aside {
  display: flex;
  align-items: baseline; }
  .check-label-aside__text {
    min-width: 70px;
    font-size: 13px;
    line-height: 20px;
    color: #646c9a; }

.check-main-label {
  color: #646c9a; }
  .check-main-label + .check-group {
    margin-top: 16px; }

button {
  padding: 0;
  outline: none;
  background: none;
  border: none;
  cursor: pointer; }

.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  border-radius: 3px;
  padding: 5px 15px;
  border: 1px solid #e2e5ec;
  color: #595d6e;
  transition: 0.2s; }
  .btn:disabled {
    pointer-events: none;
    background-color: #9c9c9c;
    opacity: 0.7; }
  .btn svg {
    margin-right: 5px; }
  .btn:hover {
    border-color: #595d6e; }
  .btn:active {
    -webkit-transform: scale(0.93);
            transform: scale(0.93); }
  .btn-lg {
    background-color: #9c9c9c;
    padding: 8px 18px;
    border-radius: 3px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    color: #fff;
    box-shadow: 0px 3px 16px #5a78ff26; }
  .btn-sm {
    background-color: #9c9c9c;
    padding: 6px 18px;
    border-radius: 3px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    color: #fff;
    box-shadow: 0px 3px 16px #5a78ff26; }
  .btn__tree {
    margin-right: 4px;
    margin-left: -13px; }
  .btn-plus {
    position: relative;
    padding-left: 35px; }
    .btn-plus:before {
      content: '';
      position: absolute;
      width: 12px;
      height: 1px;
      top: 48%;
      left: 15px;
      background-color: #fff; }
    .btn-plus:after {
      content: '';
      top: 48%;
      left: 15px;
      background-color: white;
      position: absolute;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      width: 12px;
      height: 1px; }
  .btn-blue {
    background-color: #567cfb; }
  .btn-pink {
    background-color: #fd397a; }
  .btn-green {
    background-color: #0abe8c; }
  .btn-cancel {
    background-color: white;
    border: 1px solid #e2e5ec;
    color: #595d6e;
    min-width: 100px;
    justify-content: center; }
  .btn-icon {
    border: none;
    padding: 5px; }
    .btn-icon svg {
      margin: 0; }

.label {
  display: inline-block;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.81px;
  border-radius: 2px;
  padding: 4px 8px;
  background: transparent;
  color: #fff;
  font-weight: 300; }
  .label-sm {
    padding: 2px 4px;
    font-size: 10px; }
  .label-bold {
    font-weight: 600; }
  .label-round {
    border-radius: 4px; }
  .label-up {
    text-transform: uppercase; }
  .label--danger {
    background-color: #FD397A; }
  .label--warning {
    background-color: #ffb822; }
  .label--info {
    background-color: #5D78FF; }
  .label--info-light {
    background-color: #44B2F1; }
  .label--success {
    background-color: #0ABB87; }
  .label--default {
    background-color: #93A2DD; }
  .label--purple {
    background-color: #AE22FF; }

.tree {
  color: #595d6e;
  padding: 0 20px; }
  .tree__line {
    position: absolute;
    z-index: 2; }
    .tree__line--vertical {
      top: 0px;
      bottom: 5px;
      left: 0;
      border-left: 1px dashed rgba(89, 125, 255, 0.4);
      -webkit-transform: translate(2px, -30px);
              transform: translate(2px, -30px); }
    .tree__line--horizontal {
      top: 50%;
      left: 23px;
      width: 16px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      border-bottom: 1px dashed rgba(89, 125, 255, 0.4); }
  .tree__item {
    width: 100%; }
    .tree__item > .table {
      width: calc(100% + 40px);
      margin-left: -20px;
      border-bottom: 1px solid #ebedf2; }
      .tree__item > .table td {
        padding: 20px 20px 20px 0; }
      .tree__item > .table .button__tree {
        margin-right: 5px; }
      .tree__item > .table .table__body tr td {
        width: 20%; }
        .tree__item > .table .table__body tr td:first-child {
          min-width: 400px; }
    .tree__item > .tree__list {
      position: relative; }
      .tree__item > .tree__list > .tree__item > .table {
        position: relative;
        margin-left: -20px; }
        .tree__item > .tree__list > .tree__item > .table .table__body tr td:first-child {
          padding-left: 50px; }

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000000b4;
  z-index: 1000;
  display: flex; }

.popup {
  max-width: 424px;
  margin: auto;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  opacity: 0;
  transition: 0.2s; }
  .popup.active {
    opacity: 1; }
  .popup__header {
    padding: 18px 30px;
    display: flex;
    align-items: center;
    position: relative; }
    .popup__header button:only-child {
      margin-left: auto; }
    .popup__header:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ebedf2; }
  .popup__body__images {
    overflow-y: scroll;
    max-height: calc(90vh - 120px); }
    .popup__body__images::-webkit-scrollbar {
      display: none; }
  .popup__footer {
    text-align: end;
    padding: 10px 15px 10px 15px;
    border-top: 1px solid #ebedf2; }
    .popup__footer button {
      margin-left: 11px; }
    .popup__footer img {
      vertical-align: middle; }
  .popup.popup__image {
    max-width: 650px; }
    .popup.popup__image .popup__body {
      padding: 0 30px 30px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .popup.popup__image .popup__body img {
        max-width: 100%; }
    .popup.popup__image .popup__header {
      padding: 18px 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .popup.popup__image .popup__header:after {
        display: none; }
  .popup.popup__login .popup__body {
    padding: 20px; }
    .popup.popup__login .popup__body .login_button {
      text-align: center; }

.image-uploader {
  display: flex;
  padding: 20px; }
  .image-uploader__box {
    width: 130px;
    height: 130px;
    padding: 10px;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 10px;
    transition: 0.2s;
    overflow: hidden; }
    .image-uploader__box img {
      max-width: 100%; }
    .image-uploader__box.new {
      cursor: pointer; }
      .image-uploader__box.new .inner {
        text-align: center;
        color: #a2a5b9; }
      .image-uploader__box.new:hover {
        background: rgba(226, 229, 236, 0.5); }
    .image-uploader__box__overlay {
      position: absolute;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-radius: 4px;
      transition: 0.2s;
      background: rgba(93, 120, 255, 0.8);
      box-shadow: 0px 0px 13px #523f690d; }
      .image-uploader__box__overlay .btn {
        margin: 5px;
        min-width: 80px;
        padding: 2px 10px;
        min-height: 28px;
        border-radius: 5px; }
        .image-uploader__box__overlay .btn span {
          padding-left: 5px;
          font-size: 13px;
          line-height: 16px; }
        .image-uploader__box__overlay .btn-edit {
          background-color: #44b2f1; }
        .image-uploader__box__overlay .btn-delete {
          background-color: #fd397a; }
          .image-uploader__box__overlay .btn-delete svg {
            max-width: 20px; }
      .image-uploader__box__overlay:hover {
        opacity: 1; }

.breadcrumbs__container {
  padding: 5px 8px;
  background-color: #fff;
  box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
  position: fixed;
  z-index: 10;
  top: 50px;
  left: 265px;
  right: 0;
  transition: 0.2s; }

.breadcrumbs__list {
  display: flex;
  align-items: center; }

.breadcrumbs__item {
  display: inline-flex;
  align-items: center; }
  .breadcrumbs__item:not(:last-child)::after {
    content: '';
    display: inline-block;
    background: url(/static/media/breadcrumbs-arrow.3245a8a4.svg);
    background-size: 8px 15px;
    background-repeat: no-repeat;
    width: 8px;
    height: 15px;
    margin-bottom: 2px; }
  .breadcrumbs__item a,
  .breadcrumbs__item span {
    display: inline-block;
    padding: 0 10px;
    font-size: 11px;
    line-height: 20px;
    color: #74788d; }
  .breadcrumbs__item a {
    cursor: pointer; }
    .breadcrumbs__item a:hover {
      text-decoration: underline; }

.actionbar {
  background: #fff;
  box-shadow: 0px 0px 13px #523f690c;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 265px;
  transition: 0.2s; }
  .actionbar__container {
    padding: 10px 20px;
    display: flex;
    align-items: center; }
    .actionbar__container.align-space {
      justify-content: space-between; }
    .actionbar__container.align-right {
      justify-content: flex-end; }
  .actionbar__group {
    display: flex;
    align-items: center; }
    .actionbar__group .btn {
      margin-left: 20px; }
      .actionbar__group .btn:only-child {
        margin-left: 0; }

.nav-category {
  height: 40px;
  padding: 12px 28px;
  cursor: default; }

.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
  height: 40px;
  transition: 0.3s; }
  .nav-item__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-basis: calc(100% - 20px);
    max-width: calc(100% - 20px); }
  .nav-item path[fill] {
    transition: 0.3s;
    fill: #494b74; }
  .nav-item .svg-rect {
    transition: 0.3s;
    fill: #494b74; }
  .nav-item.active, .nav-item:hover {
    background-color: #1a1a27; }
    .nav-item.active path, .nav-item:hover path {
      fill: #5d78ff !important; }
    .nav-item.active .svg-rect, .nav-item:hover .svg-rect {
      fill: #5d78ff !important; }
  .nav-item__title {
    flex-grow: 1;
    margin-left: 12px;
    transition: 0.3s;
    height: 100%;
    padding: 10px 0;
    line-height: 20px; }
    .nav-item__title:hover {
      color: #dcdde5; }

.sidebar {
  height: 100vh;
  position: fixed;
  width: 265px;
  transition: 0.2s;
  left: 0;
  top: 0;
  background-color: #1e1e2d;
  overflow-y: scroll; }
  .sidebar::-webkit-scrollbar {
    width: 0;
    background: transparent; }
  .sidebar__header {
    display: flex;
    background-color: #1a1a27;
    height: 60px;
    align-items: center;
    padding: 20px 28px;
    transition: 0.3s; }
    .sidebar__header path[fill] {
      transition: 0.3s; }
    .sidebar__header:hover .arrow {
      fill: #5d78ff; }
  .sidebar__logo {
    flex-grow: 1; }
    .sidebar__logo__background {
      fill: #ff8e36; }
  .sidebar.collapsed {
    width: 70px; }
    .sidebar.collapsed .sidebar__header {
      padding: 20px;
      justify-content: center; }
    .sidebar.collapsed .sidebar__logo {
      display: none; }
    .sidebar.collapsed .sidebar__button {
      transition: 0.3s;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
    .sidebar.collapsed .nav-category {
      font-size: 0; }
    .sidebar.collapsed .nav-item {
      padding: 0 10px;
      justify-content: center; }
      .sidebar.collapsed .nav-item__button, .sidebar.collapsed .nav-item__title {
        display: none; }
    .sidebar.collapsed + .main-container {
      margin-left: 70px;
      width: calc(100% - 70px); }
      .sidebar.collapsed + .main-container .main-navbar {
        width: calc(100% - 70px); }
      .sidebar.collapsed + .main-container .actionbar {
        left: 70px; }
      .sidebar.collapsed + .main-container .breadcrumbs__container {
        left: 70px; }

.main-container {
  margin-left: 265px;
  width: calc(100% - 265px);
  transition: 0.2s; }

.main-navbar {
  width: calc(100% - 265px);
  transition: 0.2s;
  position: fixed;
  z-index: 800;
  top: 0;
  min-height: 50px;
  background: linear-gradient(180deg, #ff8e36 0%, #dd3b00 100%); }
  .main-navbar__action {
    display: flex;
    align-items: center; }
    .main-navbar__action .btn-qrcode {
      margin-right: 10px; }
  .main-navbar__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 8px; }
  .main-navbar__app-name {
    display: flex;
    align-items: center;
    cursor: default; }
    .main-navbar__app-name .label {
      background-color: #fff;
      color: #ff8e36; }
  .main-navbar .nav-user {
    margin-right: 20px; }
  .main-navbar .nav-button {
    display: flex;
    align-items: center;
    padding: 0;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s; }
    .main-navbar .nav-button:hover .nav-button__logo::after {
      opacity: 0.2; }
    .main-navbar .nav-button__logo {
      position: relative; }
      .main-navbar .nav-button__logo--right {
        order: 2; }
      .main-navbar .nav-button__logo:after {
        content: "";
        position: absolute;
        transition: 0.3s;
        top: 0;
        left: 0;
        border-radius: 5px;
        width: 24px;
        height: 24px;
        background-color: #fff;
        opacity: 0.1; }

.login-container {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1a27; }
  .login-container .nav-button {
    position: absolute;
    top: 20px;
    left: 20px; }
  .login-container::before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 35%;
    -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
    background: linear-gradient(180deg, #ff8e36 0%, #dd3b00 100%); }

.login-wrapper {
  max-width: 425px;
  margin: 40px;
  width: 100%; }

.login-header {
  display: flex;
  justify-content: center;
  padding-bottom: 50px; }

.login-panel {
  position: relative;
  z-index: 2;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px; }

.login-submit {
  text-align: right;
  padding-top: 10px; }

.scroll-lock {
  overflow: hidden; }

.change_app_modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(26, 26, 39, 0.9); }
  .change_app_modal .btn-close {
    position: absolute;
    top: 20px;
    right: 20px; }
  .change_app_modal__container {
    width: 100%;
    max-width: 570px;
    display: flex;
    flex-flow: wrap; }
  .change_app_modal__item {
    border: 2px solid transparent;
    flex-basis: 265px;
    max-width: 265px;
    border-radius: 5px;
    margin: 10px;
    padding: 16px 21px;
    background: #0e0e1b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s; }
    .change_app_modal__item .arrow {
      margin-left: 10px; }
      .change_app_modal__item .arrow path {
        transition: 0.2s; }
    .change_app_modal__item:hover {
      border-color: #5D78FF; }
      .change_app_modal__item:hover .arrow path {
        fill: #5d78ff; }

.dzu-dropzone::-webkit-scrollbar {
  display: none; }

.dzu-dropzone label {
  display: flex; }

.nav-item__icon {
  flex-basis: 25px;
  max-width: 25px; }

.nav-item path[fill],
.nav-item rect[fill] {
  transition: 0.3s;
  fill: #494b74; }

.nav-item path[fill="none"],
.nav-item rect[fill="none"] {
  fill: none; }

.nav-item:hover path[fill],
.nav-item:hover rect[fill], .nav-item.active path[fill],
.nav-item.active rect[fill] {
  fill: #5d78ff; }

.nav-item:hover path[fill="none"],
.nav-item:hover rect[fill="none"], .nav-item.active path[fill="none"],
.nav-item.active rect[fill="none"] {
  fill: none !important; }

.main-navbar__bottom .tab-list__item--active path[fill="none"] {
  fill: none; }

.main-navbar__bottom .tab-list__item--active rect[fill="#ffffff"] {
  fill: #5d78ff; }

.wrapper {
  display: flex; }
  .wrapper.jc-sb {
    justify-content: space-between; }
  .wrapper.jc-fs {
    justify-content: flex-start; }
  .wrapper.jc-fe {
    justify-content: flex-end; }
  .wrapper.al-c {
    align-items: center; }
  .wrapper.al-fe {
    align-items: flex-end; }
  .wrapper.al-fs {
    align-items: flex-start; }

.block {
  padding: 20px;
  flex: 1 1; }

.popup.lg {
  max-width: 800px; }

.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px #523f690c;
  border-radius: 5px;
  margin-bottom: 20px; }
  .dashboard__tabs {
    display: flex;
    background-color: #f2f3f8; }
    .dashboard__tabs .tab {
      flex: 1 1;
      background-color: #ebecf3;
      padding: 9px 0;
      text-align: center;
      cursor: pointer;
      position: relative; }
      .dashboard__tabs .tab:last-child::before {
        display: none; }
      .dashboard__tabs .tab:hover {
        background-color: #e5e6ef; }
      .dashboard__tabs .tab.active {
        background-color: white;
        position: relative; }
        .dashboard__tabs .tab.active:hover {
          background-color: white; }
        .dashboard__tabs .tab.active:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #5d78ff;
          height: 2px; }
        .dashboard__tabs .tab.active:before {
          display: none; }
  .dashboard--small {
    flex-basis: 49.2%; }
  .dashboard__button-section {
    padding: 20px 30px; }
  .dashboard__list-item {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    position: relative; }
    .dashboard__list-item .country {
      margin-right: auto; }
      .dashboard__list-item .country-label--hidden {
        visibility: hidden; }
    .dashboard__list-item .no-margin {
      margin: 0; }
    .dashboard__list-item:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ebedf2; }
  .dashboard__form {
    padding: 30px; }
    .dashboard__form__submit {
      padding: 20px 30px; }
    .dashboard__form__footer {
      border-top: 1px solid #ebedf2;
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px; }
      .dashboard__form__footer.md {
        justify-content: flex-start; }
        .dashboard__form__footer.md .box {
          width: 100%;
          max-width: 600px;
          display: flex;
          justify-content: flex-end; }
    .dashboard__form--pr-10 {
      padding-right: 10px; }
  .dashboard__channel-label {
    padding: 30px;
    padding-top: 0; }
    .dashboard__channel-label .channel-label-text {
      margin-bottom: 11px; }
  .dashboard__header {
    display: flex;
    padding: 20px 30px 20px;
    align-items: center;
    border-bottom: 1px solid #ebedf2; }
    .dashboard__header--edit {
      padding: 18px 30px; }
  .dashboard__title {
    margin-right: auto;
    margin-left: 10px; }
  .dashboard__searchfield {
    padding: 18px 30px;
    display: flex; }
    .dashboard__searchfield .search-box {
      flex: 1 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px; }

.main-dashboard {
  min-height: 100vh;
  padding-top: 50px;
  background-color: #f2f3f8;
  position: relative; }
  .main-dashboard__container {
    padding: 50px 20px; }
  .main-dashboard__top-line {
    padding: 0px 20px 0px 8px;
    background-color: #fff;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
    position: fixed;
    z-index: 10;
    top: 50px;
    left: 265px;
    right: 0;
    transition: 0.2s; }
    .main-dashboard__top-line .top-line__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .main-dashboard__top-line .top-line__wrapper .breadcrumbs__container {
        position: static;
        background-color: transparent;
        box-shadow: none;
        padding: 5px 0; }

.icon__container path {
  fill: #5d78ff; }

.icon__container circle {
  fill: #5d78ff; }

.icon__container .svg-rect {
  fill: #5d78ff; }

.dashboard-wrapper-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.column-1 {
  flex-basis: calc(33.3333% - 10px); }
  .column-1--container {
    display: flex;
    flex-direction: column; }

.column-2 {
  flex-basis: calc(66.666% - 10px); }

.column-3 {
  flex-basis: 100%; }

.column-half {
  flex-basis: calc(50% - 10px); }

.table {
  width: 100%;
  background-color: transparent; }
  .table__header tr {
    border-bottom: 1px solid #ebedf2; }
  .table__header th {
    padding: 18px 0px; }
    .table__header th:first-child {
      padding-left: 28px; }
  .table__body tr {
    border-bottom: 1px solid #ebedf2; }
    .table__body tr:hover {
      background-color: #f9f9f9; }
  .table__body td {
    padding: 20px 0px;
    vertical-align: middle; }
  .table__body tr td:first-child {
    padding-left: 28px; }
    .table__body tr td:first-child.child {
      position: relative; }
      .table__body tr td:first-child.child--1 {
        padding-left: 63px; }
      .table__body tr td:first-child.child--2 {
        padding-left: 98px; }
  .table__body tr .category-span {
    position: relative; }
    .table__body tr .category-span::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -44px;
      width: 23px;
      height: 2px;
      background-color: #597dff;
      opacity: 0.4;
      top: 49%; }
    .table__body tr .category-span--hide::before {
      display: none; }
  .table__double-label {
    display: inline-block;
    margin: 5px;
    white-space: nowrap;
    font-family: "Poppins", sans-serif; }
  .table__label {
    background-color: #ececec;
    padding: 2px 8px;
    margin-right: 8px;
    font-size: 11px;
    line-height: 16px;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    font-weight: 500; }
    .table__label--green {
      color: #0abbbb;
      background-color: rgba(10, 187, 187, 0.2); }
    .table__label--red {
      color: #fd3939;
      background-color: rgba(253, 57, 57, 0.2); }
    .table__label--yellow {
      color: #ffb822;
      background-color: rgba(255, 184, 34, 0.2); }
    .table__label--violet {
      color: #6744f1;
      background-color: rgba(103, 68, 241, 0.2); }
  .table__label-left {
    background-color: #ececec;
    padding: 3px 8px;
    font-size: 11px;
    border-radius: 4px 0 0 4px;
    font-weight: 500; }
    .table__label-left--green {
      color: #0abbbb;
      background-color: rgba(10, 187, 187, 0.2); }
    .table__label-left--red {
      color: #fd3939;
      background-color: rgba(253, 57, 57, 0.2); }
    .table__label-left--yellow {
      color: #ffb822;
      background-color: rgba(255, 184, 34, 0.2); }
    .table__label-left--violet {
      color: #6744f1;
      background-color: rgba(103, 68, 241, 0.2); }
  .table__label-right {
    padding: 2px 8px;
    font-size: 11px;
    border: 1px solid #ececec;
    border-radius: 0 4px 4px 0;
    font-weight: 500; }
    .table__label-right--orange {
      color: #ff5a00;
      border-color: #ff5a00; }
    .table__label-right--blue {
      color: #0064d2;
      border-color: #0064d2; }
    .table__label-right--green {
      color: #99cc00;
      border-color: #99cc00; }
  .table__platform {
    margin-right: 10px;
    line-height: 16px;
    padding: 2px 8px;
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    border: 1px solid #ececec;
    border-radius: 4px; }
    .table__platform--orange {
      color: #ff5a00;
      border-color: #ff5a00; }
    .table__platform--blue {
      color: #0064d2;
      border-color: #0064d2; }
    .table__platform--green {
      color: #99cc00;
      border-color: #99cc00; }
  .table__icon {
    margin-right: 10px;
    cursor: pointer; }
    .table__icon path {
      fill: #91a0da; }
    .table__icon--purple svg {
      -webkit-transform: translateY(-2px) scale(1.2);
              transform: translateY(-2px) scale(1.2); }
    .table__icon--purple path {
      fill: #fd397a; }
  .table__inputs td {
    padding-right: 30px; }
  .table__photo {
    max-width: 40px;
    max-height: 40px;
    display: block;
    margin-top: auto;
    margin-bottom: auto; }

.button__tree {
  position: relative; }
  .button__tree::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.page-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f3f8;
  display: flex;
  justify-content: center;
  align-items: center; }

.tree__line--horizontal {
  width: 27px; }

.tree__line--vertical {
  bottom: 7px; }

.aside-tabs {
  background-color: #ebecf3;
  border-radius: 4px;
  overflow: hidden; }
  .aside-tabs .tab {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 7px 30px;
    min-height: 50px;
    transition: 0.2s; }
    .aside-tabs .tab .icon {
      flex-basis: 30px;
      max-width: 30px;
      padding-right: 10px;
      display: flex;
      align-items: center; }
      .aside-tabs .tab .icon svg {
        max-width: 100%; }
    .aside-tabs .tab .text p {
      font-size: 13px;
      line-height: 18px;
      font-weight: 300; }
    .aside-tabs .tab .buttons-wrapper {
      display: flex;
      align-items: center;
      margin-right: 0;
      margin-left: auto; }
      .aside-tabs .tab .buttons-wrapper .order-buttons {
        margin-right: 5px; }
    .aside-tabs .tab::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 2px;
      background-color: #5d78ff;
      opacity: 0;
      transition: 0.2s; }
    .aside-tabs .tab:not(:last-child)::before {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      background-color: rgba(162, 165, 185, 0.14); }
    .aside-tabs .tab.tab-header {
      cursor: default; }
      .aside-tabs .tab.tab-header h2 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #48465b; }
    .aside-tabs .tab.sub-header {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .aside-tabs .tab.sub-header p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #595d6e; }
    .aside-tabs .tab.tab-footer {
      padding: 10px 30px;
      display: flex;
      align-items: center;
      cursor: default;
      justify-content: flex-end; }
    .aside-tabs .tab.active {
      background-color: #fff; }
      .aside-tabs .tab.active::after {
        opacity: 1; }
  .aside-tabs__action {
    padding: 20px;
    text-align: center; }

.listing-search {
  display: flex;
  align-items: center;
  padding: 0 30px; }
  .listing-search__info {
    flex-basis: 240px;
    max-width: 240px; }
    .listing-search__info span {
      font-size: 13px;
      line-height: 20px;
      color: rgba(72, 70, 91, 0.5); }
  .listing-search__input {
    flex: 1 1; }
  .listing-search__select {
    flex-basis: 300px;
    max-width: 300px; }
    .listing-search__select div[class$="indicatorContainer"] {
      padding: 5px; }
      .listing-search__select div[class$="indicatorContainer"] svg {
        max-width: 15px;
        fill: #93a2dd; }

.calendar-group > div {
  width: 100%; }

.calendar-group .react-datetime-picker__wrapper {
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  border: none;
  border: 1px solid #e2e5ec;
  width: 100%;
  outline: none;
  transition: 0.2s; }

.calendar-group .react-datetime-picker__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: #f3f6f9;
  border-left: 1px solid #e2e5ec; }

.calendar-group .react-datetime-picker__inputGroup {
  padding: 5px 10px; }

.bottom-action-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 30px; }

.channel-select {
  padding: 5px 25px 20px; }
  .channel-select div[class$="indicatorContainer"] {
    padding: 2px 5px; }
    .channel-select div[class$="indicatorContainer"] svg {
      max-width: 15px;
      fill: #A2A5B9; }

.client-icon {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f8;
  border-radius: 50%; }
  .client-icon__container {
    display: flex;
    align-items: center;
    justify-content: center; }

.client-title {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: #48465b;
  margin-bottom: 10px; }

.client-form-check {
  min-height: 150px;
  padding-top: 12px; }

.product-group-container .products-list {
  font-weight: 100;
  color: #595d6e; }
  .product-group-container .products-list > li .table-head tr th:not(:first-child) {
    padding-left: 10px; }
  .product-group-container .products-list .product {
    cursor: pointer;
    border-bottom: 1px solid #ebedf2; }
    .product-group-container .products-list .product.active {
      background-color: #f9f9f9; }
    .product-group-container .products-list .product:hover {
      background-color: rgba(249, 249, 249, 0.7); }
    .product-group-container .products-list .product tr {
      border-bottom: none; }
      .product-group-container .products-list .product tr:hover {
        background-color: initial; }
    .product-group-container .products-list .product .table__body {
      margin: 0 20px; }
      .product-group-container .products-list .product .table__body tr td {
        padding: 12px 10px; }
      .product-group-container .products-list .product .table__body tr td:first-child {
        padding-left: 50px;
        position: relative; }
        .product-group-container .products-list .product .table__body tr td:first-child button {
          position: absolute;
          top: 50%;
          left: 30px;
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
      .product-group-container .products-list .product .table__body tr td:last-child {
        padding-right: 10px; }

.product-group-container .products-list .inner-table {
  padding: 0 30px 30px;
  border-radius: 4px;
  cursor: default; }
  .product-group-container .products-list .inner-table .table {
    background-color: white;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05); }
    .product-group-container .products-list .inner-table .table tr {
      border-bottom: 1px solid #ebedf2 !important; }
    .product-group-container .products-list .inner-table .table__head--inner tr th {
      padding: 10px;
      padding-left: 5px;
      padding-bottom: 10px;
      font-weight: 600; }
    .product-group-container .products-list .inner-table .table__head--inner tr th:first-child {
      padding-left: 20px; }
    .product-group-container .products-list .inner-table .table__body--inner tr {
      transition: 0.2s;
      cursor: pointer; }
      .product-group-container .products-list .inner-table .table__body--inner tr:hover {
        background-color: rgba(249, 249, 249, 0.7); }
    .product-group-container .products-list .inner-table .table__body--inner td {
      padding: 10px 5px; }
    .product-group-container .products-list .inner-table .table__body--inner td:first-child {
      padding-left: 20px; }

.product-desc {
  padding: 30px; }
  .product-desc article {
    display: flex;
    max-width: 1015px;
    margin: 0 auto;
    padding: 30px 0;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: center; }
    .product-desc article div:only-child {
      flex-basis: 100%;
      max-width: 100%; }
    .product-desc article div {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px); }
      .product-desc article div h2 {
        font-size: 22px;
        line-height: 33px;
        font-weight: 600;
        letter-spacing: 0.2px;
        margin-bottom: 7px; }
      .product-desc article div p {
        font-size: 16px;
        line-height: 28px;
        color: #495962; }
      .product-desc article div ul {
        display: inline; }
        .product-desc article div ul li {
          display: inline;
          font-size: 16px;
          line-height: 28px;
          color: #495962; }
    .product-desc article picture {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      display: flex;
      justify-content: center;
      align-items: center; }
      .product-desc article picture img {
        width: 100%; }

.image-box {
  width: 70px;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 0;
  line-height: 0; }

.btn-preview {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative; }
  .btn-preview::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(93, 120, 255, 0.8);
    opacity: 0;
    transition: 0.2s;
    z-index: 1; }
  .btn-preview::after {
    content: url(/static/media/zoom.2e224cf5.svg);
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: 0.2s;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .btn-preview:hover::before, .btn-preview:hover::after {
    opacity: 1; }

.main-table__products-list {
  transition: 0.2s; }
  .main-table__products-list.searching {
    opacity: 0.4; }
  .main-table__products-list .main-table:not(.table--inner) {
    border-bottom: none; }
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr td:nth-child(1),
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr th:nth-child(1),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr td:nth-child(1),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr th:nth-child(1) {
      width: 30%; }
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr td:nth-child(2),
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr th:nth-child(2),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr td:nth-child(2),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr th:nth-child(2) {
      width: 20%; }
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr td:nth-child(3),
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr th:nth-child(3),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr td:nth-child(3),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr th:nth-child(3) {
      width: 20%; }
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr td:nth-child(4),
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr th:nth-child(4),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr td:nth-child(4),
    .main-table__products-list .main-table:not(.table--inner) .main-table__head > tr th:nth-child(4) {
      width: 20%; }
    .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr td:nth-child(1) {
      position: relative;
      padding-left: 60px; }
      .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr td:nth-child(1) .button__tree {
        width: 70px;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0; }
        .main-table__products-list .main-table:not(.table--inner) .main-table__body > tr td:nth-child(1) .button__tree::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          background-color: red; }
  .main-table__products-list .inner-table {
    padding: 0 30px 30px;
    border-radius: 4px;
    cursor: default; }
    .main-table__products-list .inner-table .table {
      background-color: white;
      box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05); }
      .main-table__products-list .inner-table .table tr {
        border-bottom: 1px solid #ebedf2 !important; }
      .main-table__products-list .inner-table .table__head--inner tr th {
        padding: 10px;
        padding-bottom: 10px;
        font-weight: 600; }
      .main-table__products-list .inner-table .table__head--inner tr th:first-child {
        padding-left: 20px; }
      .main-table__products-list .inner-table .table__body--inner td {
        padding: 10px !important; }
      .main-table__products-list .inner-table .table__body--inner td:first-child {
        padding-left: 20px !important; }

.main-table__product {
  cursor: pointer; }
  .main-table__product.active {
    background-color: #f9f9f9; }
  .main-table__product:hover {
    background-color: rgba(249, 249, 249, 0.7); }
  .main-table__product tr {
    border-bottom: none; }
    .main-table__product tr:hover {
      background-color: initial; }

.actions-container {
  position: relative; }
  .actions-container .btn-actions {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s; }
    .actions-container .btn-actions .dot {
      width: 4px;
      height: 4px;
      background-color: #b5b5c3;
      border-radius: 2px;
      margin: 0 1.5px;
      transition: 0.2s; }
  .actions-container .actions-dropdown {
    display: none;
    padding: 5px 10px;
    border-radius: 4px;
    min-width: 190px;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px #563f6926;
    position: absolute;
    right: 0;
    top: 100%; }
    .actions-container .actions-dropdown__item {
      margin: 5px 0;
      padding: 10px 5px;
      display: flex;
      align-items: center;
      transition: 0.2s; }
      .actions-container .actions-dropdown__item svg {
        margin-right: 10px; }
      .actions-container .actions-dropdown__item span {
        font-size: 13px;
        line-height: 20px;
        color: #646c9a;
        white-space: nowrap; }
      .actions-container .actions-dropdown__item:hover {
        opacity: 0.6; }
      .actions-container .actions-dropdown__item:active {
        -webkit-transform: scale(0.95);
                transform: scale(0.95); }
  .actions-container:hover .actions-dropdown {
    display: block; }
  .actions-container:hover .btn-actions {
    background-color: #f0f3ff; }
    .actions-container:hover .btn-actions .dot {
      background-color: #5d78ff; }

.order-buttons {
  display: flex;
  align-items: center; }
  .order-buttons .order-button {
    margin: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 15px;
    background: #ffffff;
    border: 1px solid #e2e5ec;
    border-radius: 4px; }
    .order-buttons .order-button[disabled] {
      opacity: 0.4; }
    .order-buttons .order-button svg {
      max-width: 14px; }
    .order-buttons .order-button.up svg {
      -webkit-transform: scaleY(-1);
              transform: scaleY(-1); }

.order-details {
  display: flex;
  padding: 20px 30px;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin: 0; }
  .order-details__date-group {
    display: flex;
    align-items: center; }
  .order-details__date {
    font-size: 13px;
    line-height: 20px;
    margin-right: 40px;
    font-weight: 300;
    color: #595d6e; }

.order-columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  .order-columns .dashboard {
    margin-bottom: 20px; }
  .order-columns .column-left {
    flex-basis: calc(60% - 10px);
    max-width: calc(60% - 10px); }
  .order-columns .column-right {
    flex-basis: calc(40% - 10px);
    max-width: calc(40% - 10px); }

.user-link {
  position: relative;
  display: inline-block;
  padding-right: 10px;
  font-weight: 300; }
  .user-link::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 2px solid #93a2dd;
    border-bottom: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    top: 9px;
    right: 0;
    transition: 0.2s; }
  .user-link span {
    font-size: 13px;
    line-height: 25px;
    color: #8da6de; }
  .user-link:hover {
    text-decoration: underline; }
    .user-link:hover::after {
      right: -4px; }

.btn-download {
  display: inline-flex;
  align-items: center;
  padding-right: 10px; }
  .btn-download span {
    font-size: 13px;
    line-height: 25px;
    font-weight: 300;
    color: #8da6de; }
  .btn-download svg {
    margin-left: 5px; }
  .btn-download:hover {
    text-decoration: underline; }

.order-client__user {
  padding: 20px 30px; }
  .order-client__user .user-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f2f8;
    border-radius: 50%;
    margin-bottom: 10px; }
  .order-client__user .user-name, .order-client__user .user-id {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e; }
  .order-client__user .user-name {
    font-weight: 500;
    margin-bottom: 5px; }
  .order-client__user .user-id {
    font-weight: 400; }

.order-client__address {
  padding: 20px 30px 0;
  border-top: 1px solid #ebedf2; }
  .order-client__address .title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #48465b;
    margin-bottom: 14px; }
  .order-client__address .group {
    padding-bottom: 18px; }
    .order-client__address .group p {
      font-size: 13px;
      line-height: 25px;
      font-weight: 300;
      color: #595d6e; }

.row-info {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .row-info .box {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .row-info__label {
    font-size: 13px;
    line-height: 20px;
    color: #595d6e;
    font-weight: 300; }
    .row-info__label.bold {
      font-weight: 500; }
  .row-info .table__label {
    margin-right: 0; }
  .row-info:not(:last-child) {
    border-bottom: 1px solid #ebedf2; }

.notes p {
  font-size: 13px;
  line-height: 20px;
  color: #595d6e;
  font-weight: 300; }

.logs-list__item {
  padding: 25px 30px; }
  .logs-list__item:not(:last-child) {
    border-bottom: 1px solid #ebedf2; }
  .logs-list__item p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 300;
    color: #595d6e; }
    .logs-list__item p.title {
      font-weight: 400;
      color: #646c9a; }

.summary .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 13px; }
  .summary .row span {
    font-size: 13px;
    line-height: 20px;
    font-weight: 300;
    color: #595d6e; }
  .summary .row.discount span {
    color: #e42920; }

.summary-details {
  padding: 8px 30px;
  border-bottom: 1px solid #ebedf2; }

.summary-total {
  padding: 8px 30px; }
  .summary-total span {
    font-weight: 50; }

.status-select {
  width: 200px; }
  .status-select div[class$="indicatorContainer"] {
    padding: 2px 5px; }
    .status-select div[class$="indicatorContainer"] svg {
      max-width: 15px;
      fill: #a2a5b9; }

.order-lines .btn-preview {
  width: 40px;
  height: 40px; }

.delivery-table {
  padding: 20px 0; }
  .delivery-table .box-sm {
    flex-basis: 16%;
    max-width: 16%; }
  .delivery-table .box-md {
    flex-basis: 28%;
    max-width: 28%;
    border-left: 2px solid #ebedf2; }
  .delivery-table__header {
    display: flex;
    align-items: center; }
    .delivery-table__header .header-title {
      display: flex;
      align-items: center;
      padding: 20px 25px; }
      .delivery-table__header .header-title span {
        font-size: 15px;
        line-height: 20px;
        color: #48465b; }
      .delivery-table__header .header-title svg {
        margin-right: 13px; }
  .delivery-table__body .speditor-row {
    display: flex;
    align-items: center; }
    .delivery-table__body .speditor-row .box {
      padding: 20px 10px; }
      .delivery-table__body .speditor-row .box-sm {
        padding-left: 30px; }
      .delivery-table__body .speditor-row .box-md {
        min-height: 130px; }
      .delivery-table__body .speditor-row .box .speditor_name {
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        color: #595d6e; }
      .delivery-table__body .speditor-row .box .box-form {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 250px;
        margin: 0 auto; }
        .delivery-table__body .speditor-row .box .box-form .column {
          padding: 0 10px; }
          .delivery-table__body .speditor-row .box .box-form .column .check-group {
            margin-top: 10px; }
          .delivery-table__body .speditor-row .box .box-form .column .inp-wrapper {
            flex-direction: row-reverse; }
            .delivery-table__body .speditor-row .box .box-form .column .inp-wrapper label {
              min-width: 25px;
              padding-right: 0;
              padding-left: 5px;
              margin-bottom: 0; }
            .delivery-table__body .speditor-row .box .box-form .column .inp-wrapper .inp-group {
              width: 70px; }
          .delivery-table__body .speditor-row .box .box-form .column .select-group {
            padding-right: 25px;
            position: relative; }
            .delivery-table__body .speditor-row .box .box-form .column .select-group .label-form {
              position: absolute;
              right: -15px;
              width: 25px;
              top: 10px; }
    .delivery-table__body .speditor-row:nth-child(odd) {
      background: #fbfbff; }

.rc-md-navigation {
  background: #fff; }

.rc-md-editor .editor-container .sec-md .input {
  padding-top: 20px; }
  .rc-md-editor .editor-container .sec-md .input::-webkit-scrollbar {
    display: none; }

.rc-md-editor .tool-bar {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0; }

